import React from 'react'

import { Paragraph, Provider } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { CodeBox } from '../../components/CodeBox'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

const ProviderCode = `<Provider locale={locale}>
  <App />
</Provider>`

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Provider"
    components={[{ component: Provider }]}
  >
    <Section>
      <Paragraph>
        <Code>Provider</Code>-komponentilla wrapataan koko React-sovellus.
        Komponentin avulla muut sovelluksessa käytettävät Style Guide
        -komponentit saavat käyttöönsä valitun kielen, sekä yleiset käännökset.
      </Paragraph>
      <CodeBox language="tsx">{ProviderCode}</CodeBox>
    </Section>
  </Content>
)

export default Page
